.Footer {
    padding: 0 2rem;
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    padding-bottom: 6rem;
}

.ligne {
    padding: 0 0;
    color: var(--lightgray);
    border: 1px solid white;
}

.networks {
    display: flex;
    gap: 4rem;
}

.networks>img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo {
    align-items: center;
    cursor: pointer;
}

.footer-blur-1 {
    width: 22rem;
    height: 20rem;
    left: 5rem;
}

.footer-blur-2 {
    width: 22rem;
    height: 20rem;
    filter: blur(200px);
    background: red;
    right: 5rem;
}