.plans-container {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.plans-header {
    display: flex;
    gap: 2rem;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    font-style: italic;
}

.plans {
    display: flex;
    gap: 2rem;
    justify-content: center;
    position: relative;
}

.plan {
    padding: 2rem;
    background: var(--caloryCard);
    border: 0px solid transparent;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: white;
    margin-left: 15rem;
    margin-right: 10rem;
}

.plan>:nth-child(1) {
    width: 2.2rem;
    height: 2.2rem;
}

.plans>:nth-child(2n+1) {
    fill: var(--orange);
}

.plans>:nth-child(2n) {
    background: var(--planCard);
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    position: absolute;
    top: -2rem;
    bottom: -2rem;
    fill: white;

}

.plans>:nth-child(2n)>.btn {
    color: var(--orange);
}

.plan-name {
    font-weight: bold;
    font-size: 1.2rem;
}

.plan-price {
    font-size: 3rem;
    font-weight: bold;
}

.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
}

.feature {
    display: flex;
    gap: 1rem;
    font-size: 1rem;
    align-items: center;
}

.feature>img {
    width: 1rem;
    height: 1rem;
}

.plans-blur-1 {
    width: 22rem;
    height: 30rem;
    left: 5rem;
}

.plans-blur-2 {
    width: 22rem;
    height: 30rem;
    right: 5rem;
}

@media screen and (max-width: 780px) {
    .plans {
        flex-direction: column;
        align-items: center;
    }

    .plans-header {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: x-large;
    }
}