.Join {
    padding: 0 2rem;
    display: flex;
    gap: 1rem;
}

.left-j {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
}

.right-j {
    display: flex;
    flex: 1;
    align-items: flex-end;
}

.tiret {
    border: 1px solid var(--orange);
    height: 0.15rem;
    width: 11rem;
}

.text {
    font-size: 3rem;
    font-weight: bold;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 0.5;
}

.email-container {
    display: flex;
    gap: 3rem;
    padding: 1rem 2rem;
    background: gray;
}

.email-container>input {
    background: transparent;
    border: none;
    color: var(--lightgray);
    outline: none;
    cursor: pointer;
}

::placeholder {
    color: var(--lightgray);
}

.email-container>button {
    background: var(--orange);
    border: none;
    color: white;
}

@media screen and (max-width: 780px) {
    .Join {
        flex-direction: column;
        font-size: x-large;
    }
}